import "instantsearch.css/themes/algolia-min.css";
import React from "react";
import {
  InstantSearch,
  Hits,
  SortBy,
  SearchBox,
  Pagination,
  Highlight,
  ClearRefinements,
  RefinementList,
  Configure,
  Snippet
} from "react-instantsearch-dom";
import "./App.css";
import { instantMeiliSearch } from "@meilisearch/instant-meilisearch";

const searchClient = instantMeiliSearch(
  "https://meilisearch-ondc.dataorc.in",
  "VUc0RKgaAFBD3JSDDGCS0XMgfZ3eQpQ5EqqJaLSC3rU",
  {
    finitePagination: true
  }
);

const App = () => (
  <div className="ais-InstantSearch">
    <h1>ONDC Instant Search</h1>
    <h2>
      Search in ONDC product items
    </h2>
    <p>
      This is not the official ONDC dataset but only for demo purpose. Enjoy
      searching!
    </p>
    <InstantSearch indexName="items" searchClient={searchClient}>
      <div className="left-panel">
        <ClearRefinements />
        <SortBy
          defaultRefinement="items"
          items={[
            { value: "items", label: "Relevant" },
            {
              value: "items:price.value:asc",
              label: "Price"
            }
          ]}
        />

        <h2>Categories</h2>
        <RefinementList attribute="category_id" />
        <h2>Brands</h2>
        <RefinementList attribute="provider_name" />

        <Configure
          hitsPerPage={6}
          attributesToSnippet={["description:50"]}
          snippetEllipsisText={"..."}
        />
      </div>
      <div className="right-panel">
        <SearchBox />
        <Hits hitComponent={Hit} />
        <Pagination showLast={true} />
      </div>
    </InstantSearch>
  </div>
);

const Hit = ({ hit }) => (
  <div key={hit.id}>
    <div className="hit-name">
      <Highlight attribute="descriptor.name" hit={hit} />
    </div>
    <img src={hit?.descriptor?.symbol} align="left" alt="" />
    <div className="hit-description">
      <Snippet attribute="descriptor.long_desc" hit={hit} />
    </div>
    <div className="hit-info">price: {hit?.price?.currency} {hit?.price?.value}</div>
    <div className="hit-info">Category: {hit?.category_id?.id || hit?.category_id}</div>
    <div className="hit-info">Brand: {hit?.provider_name}</div>
  </div>
);

export default App;
